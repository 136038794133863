import React from "react";

const Error404: React.FC = () => {
  return (
    <div className="Error404">
      <h1>Error404</h1>
    </div>
  );
};

export default Error404;
