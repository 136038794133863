import * as React from "react";

export interface ICreativeProps {}

export default class Creative extends React.Component<ICreativeProps> {
  public render() {
    return (
      <div>
        <h1>Creative</h1>
      </div>
    );
  }
}
